/** Main structure CSS */
main > .container {
	padding: 50px;
}

main .navbar-toggler {
	cursor: pointer;
}

main .navbar-toggler:focus {
	outline: 0;
}
/** ------------------ */
/** Tasks CSS **/
.tasks-wrapper .card {
	margin-top: 25px;
}

.add-task-form .form-group {
	margin-bottom: 50px;
}

.task .actions {
	float: right;
}

.remove-task,
.edit-task {
	cursor: pointer;
	margin-left: 10px;
	color: #343a40;
}
